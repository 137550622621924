import React from 'react';
import SupportBlock from './SupportBlock'
import { connect } from 'react-redux';
import GreetingBlock from './GreetingBlock';
import ManagerBlock from './ManagersBlock';

export const Dashboard = ({globalState, user}) => {
  return (
    <>
      <GreetingBlock permissions={user.permissions} name={user.name} referenceName={user.referenceName}></GreetingBlock>
      <SupportBlock pages={globalState.pages}></SupportBlock>
      <br></br>
      <ManagerBlock pages={globalState.pages}></ManagerBlock>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    user: state.globalState.user,
    globalState: state.globalState
  }
}

export default connect(mapStateToProps)(Dashboard);
