import React, { useEffect, useState } from 'react';
import { FieldArray, Field, Fields } from 'redux-form';
import { Button, Typography, withStyles } from '@material-ui/core';
import styles from './styles';
import {AllDayCheckbox, CopyScheduleModal, DontWorkCheckbox, TimeInputAdapter} from './components';
import { connect } from 'react-redux';

const RenderTimeInputs = props => {
    const { fields, scheduleState, timezone } = props;

    return (
        <>

        <div>
            {fields.map((name, index) => {
                return (
                    <div
                        key={name}
                        style={{ display: 'flex', gap: '8px', alignItems: 'center' }}
                    >
                        <Field
                            isDisabled={scheduleState.isAllDay}
                            name={`${name}[0]`}
                            component={TimeInputAdapter}
                            label={'Начало интервала'}
                            timezone={timezone}
                        />
                        <Field
                            isDisabled={scheduleState.isAllDay}
                            name={`${name}[1]`}
                            component={TimeInputAdapter}
                            label={'Конец интервала'}
                            timezone={timezone}
                        />
                        <Button
                            variant={'contained'}
                            disabled={scheduleState.isAllDay}
                            type={'button'}
                            onClick={() => fields.remove(index)}
                        >
                            Удалить
                        </Button>
                    </div>
                );
            })}
            <div>
                {scheduleState.isWorking && (
                    <Button
                        variant={'contained'}
                        color={'primary'}
                        disabled={scheduleState.isAllDay}
                        type={'button'}
                        onClick={() => fields.push([null, null])}
                    >
                        добавить интервал
                    </Button>
                )}

            </div>
        </div>
        </>
    );
};

const EditableScheduleView = connect((state, dispatch) => ({
    currentTimezone: state.form['record-form'].values.timezone,
    dispatch,
}))(
    withStyles(styles)(({ names, classes, currentTimezone, dispatch, ...rest }) => {
        const [isOpen, setIsOpen] = useState(false);
        const [currentDay, setCurrentDay] = useState(1)
        const { schedule } = rest.record;
        const daysOfWeek = [
            'Понедельник',
            'Вторник',
            'Среда',
            'Четверг',
            'Пятница',
            'Суббота',
            'Воскресенье',
        ];

        const [scheduleState, setScheduleState] = useState({
            1: { isAllDay: false, isWorking: true },
            2: { isAllDay: false, isWorking: true },
            3: { isAllDay: false, isWorking: true },
            4: { isAllDay: false, isWorking: true },
            5: { isAllDay: false, isWorking: true },
            6: { isAllDay: false, isWorking: true },
            7: { isAllDay: false, isWorking: true },
        });

        useEffect(() => {
            if (schedule) {
                const correctScheduleState = Object.entries(schedule).reduce(
                    (acc, [key, value]) => {
                        if (value.length === 0) {
                            return { ...acc, [+key]: { isAllDay: false, isWorking: false } };
                        }
                        if (
                            value[0][0] &&
                            value[0][1] &&
                            value[0][0].startsWith('00:00') &&
                            value[0][1].startsWith('00:00')
                        ) {
                            return { ...acc, [+key]: { isAllDay: true, isWorking: true } };
                        }

                        return { ...acc, [+key]: { isAllDay: false, isWorking: true } };
                    },
                    {}
                );

                setScheduleState(correctScheduleState);
            }
        }, [schedule]);

        return (
            <div className={classes.box}>
                {names.map((name, index) => {
                    return (
                        <div key={name}>
                            <Typography variant={'title'}>{daysOfWeek[index]}</Typography>
                            <Field
                                name={`${name}`}
                                component={AllDayCheckbox}
                                label={'Станция работает весь день'}
                                scheduleState={scheduleState}
                                setScheduleState={setScheduleState}
                                index={index + 1}
                                timezone={currentTimezone}
                            />
                            <Field
                                name={`${name}`}
                                component={DontWorkCheckbox}
                                label={'Станция не работает'}
                                scheduleState={scheduleState}
                                setScheduleState={setScheduleState}
                                index={index + 1}
                            />
                            <Button
                                variant={'contained'}
                                color={'primary'}
                                type={'button'}
                                onClick={() => {
                                    setIsOpen(true);
                                    setCurrentDay(index + 1)
                                }}
                            >
                                Скопировать расписание
                            </Button>
                            <FieldArray
                                scheduleState={scheduleState[index + 1]}
                                name={`${name}`}
                                component={RenderTimeInputs}
                                timezone={currentTimezone}
                            />
                        </div>
                    );
                })}
                <CopyScheduleModal
                    isOpen={isOpen}
                    setIsOpen={setIsOpen}
                    currentDay={currentDay}
                    daysOfWeek={daysOfWeek}
                />
            </div>
        );
    })
);

const EditableScheduleField = props => {
    useEffect(() => {
        const { record } = props;
        if (!record.schedule) {
            record.schedule = {
                1: [['', '']],
                2: [['', '']],
                3: [['', '']],
                4: [['', '']],
                5: [['', '']],
                6: [['', '']],
                7: [['', '']],
            };
        }
    }, []);

    return (
        <Fields
            names={[
                `schedule.1`,
                `schedule.2`,
                `schedule.3`,
                `schedule.4`,
                `schedule.5`,
                `schedule.6`,
                `schedule.7`,
            ]}
            component={EditableScheduleView}
            {...props}
        />
    );
};
export default EditableScheduleField;
