import React from 'react';
import {
    Create,
    Datagrid,
    DateField,
    Edit,
    email,
    Filter,
    List,
    minLength,
    ReferenceField,
    ReferenceInput,
    required,
    SelectInput,
    SimpleForm,
    TextField,
    TextInput,
} from 'react-admin';
import ChangePass from './changePass/ChangePass';
import { withStyles } from '@material-ui/core/styles';
import { CardActions } from '@material-ui/core';
import { BulkDeleteButtonDisable, CustomPagination, isAdmin, ToolbarSave, stylesWide } from './utils';

const AdminUsersFilter = props => (
    <Filter {...props}>
        <SelectInput
            label="Поле"
            source="field"
            choices={[
                { id: 'user_id', name: 'Id юзера' },
                { id: 'email', name: 'Email' },
                { id: 'role', name: 'Роль' },
                { id: 'status', name: 'Статус' },
                { id: 'franchisee_id', name: 'Id франчайзи' },
                { id: 'agent_id', name: 'Id агента' },
                { id: 'partner_id', name: 'Id партнера' },
                { id: 'partner_service_id', name: 'Id сервисного партнера' },
                { id: 'created', name: 'Время создания' },
                { id: 'updated', name: 'Последнее обновление' },
            ]}
            alwaysOn
        />
        <TextInput
            label="Поиск"
            source="q"
            alwaysOn
        />
    </Filter>
);

export const AdminUsersList = props => (
    <List
        {...props}
        filters={<AdminUsersFilter />}
        bulkActionButtons={<BulkDeleteButtonDisable />}
        pagination={<CustomPagination />}
    >
        <Datagrid rowClick="edit">
            <TextField
                label="Id юзера"
                source="user_id"
            />
            <TextField
                label="Email"
                source="email"
            />
            <TextField
                label="Имя"
                source="name"
            />
            <TextField
                label="Роль"
                source="role"
            />
            <TextField
                label="Статус"
                source="status"
            />
            <ReferenceField
                label="Франчайзи"
                source="franchisee_id"
                reference="franchisee"
                linkType={false}
                allowEmpty
            >
                <TextField source="name" />
            </ReferenceField>
            <ReferenceField
                label="Агент"
                source="agent_id"
                reference="agents"
                linkType={false}
                allowEmpty
            >
                <TextField source="name" />
            </ReferenceField>
            <ReferenceField
                label="Партнер"
                source="partner_id"
                reference="partners"
                linkType={false}
                allowEmpty
            >
                <TextField source="name" />
            </ReferenceField>
            <ReferenceField
                label="Сервисный партнер"
                source="partner_service_id"
                reference="partner_services"
                linkType={false}
                allowEmpty
            >
                <TextField source="name" />
            </ReferenceField>
            <DateField
                label="Время создания"
                source="created"
                showTime
                locales="ru-RU"
            />
            <DateField
                label="Последнее обновление"
                source="updated"
                showTime
                locales="ru-RU"
            />
        </Datagrid>
    </List>
);

const AdminUserActions = ({ data, permissions }) => (
    <CardActions
        submitOnEnter={false}
        style={{ zIndex: 2, display: 'inline-block', float: 'right' }}
    >
        {isAdmin(permissions) && <ChangePass record={data} />}
    </CardActions>
);

export const EditAdminUser = withStyles(stylesWide)(({ classes, permissions, ...props }) => (
    <Edit
        classes={classes}
        {...props}
        actions={<AdminUserActions permissions={permissions} />}
    >
        <SimpleForm toolbar={<ToolbarSave />} classes={classes}>
            <TextInput
                disabled
                label="Id юзера"
                source="user_id"
                className={classes.wide}
            />
            <TextInput
                disabled
                label="Email"
                source="email"
                className={classes.wide}
            />
             <TextInput
                label="Имя"
                source="name"
                className={classes.wide}
            />
            {isAdmin(permissions) ? (
                <SelectInput
                    label="Роль"
                    source="role"
                    choices={[
                        { id: 'admin', name: 'admin' },
                        { id: 'ge_employee', name: 'ge_employee' },
                        { id: 'employee', name: 'employee' },
                        { id: 'franchisee', name: 'franchisee' },
                        { id: 'tester', name: 'tester' },
                        { id: 'partner', name: 'partner' },
                        { id: 'partner_service', name: 'partner_service' },
                        { id: 'agent', name: 'agent' },
                    ]}
                    validate={required()}
                    className={classes.wide}
                />
            ) : (
                <TextInput
                    disabled
                    label="Роль"
                    source="role"
                    className={classes.wide}
                />
            )}
            <SelectInput
                label="Статус"
                source="status"
                choices={[
                    { id: 'active', name: 'active' },
                    { id: 'inactive', name: 'inactive' },
                ]}
                className={classes.wide}
            />
            <ReferenceInput
                perPage={10000}
                label="Франчайзи"
                source="franchisee_id"
                reference="franchisee"
                allowEmpty
                className={classes.wide}
            >
                <SelectInput optionText="name" />
            </ReferenceInput>
            <ReferenceInput
                perPage={10000}
                label="Агент"
                source="agent_id"
                reference="agents"
                allowEmpty
                className={classes.wide}
            >
                <SelectInput optionText="name" className={classes.wide}/>
            </ReferenceInput>
            <ReferenceInput
                perPage={10000}
                label="Партнер"
                source="partner_id"
                reference="partners"
                allowEmpty
                className={classes.wide}
            >
                <SelectInput optionText="name" className={classes.wide}/>
            </ReferenceInput>
            <ReferenceInput
                perPage={10000}
                label="Сервисный партнер"
                source="partner_service_id"
                reference="partner_services"
                allowEmpty
                className={classes.wide}
            >
                <SelectInput optionText="name" className={classes.wide}/>
            </ReferenceInput>
            <TextInput
                disabled
                label="Время создания"
                source="created"
                className={classes.wide}
            />
            <TextInput
                disabled
                label="Последнее обновление"
                source="updated"
                className={classes.wide}
            />
        </SimpleForm>
    </Edit>
));

export const AddAdminUser = withStyles(stylesWide)(({ classes, permissions, ...props }) => (
    <Create
        classes={classes}
        {...props}
    >
        <SimpleForm classes={classes}>
            <TextInput
                label="Email"
                source="email"
                type="email"
                validate={[required(), email()]}
                className={classes.wide}
            />
            <TextInput
                label="Password"
                source="password"
                validate={[required(), minLength(8)]}
                type="password"
                className={classes.wide}
            />
            <TextInput
                label="Имя"
                source="name"
                validate={[required()]}
                className={classes.wide}
            />
            {isAdmin(permissions) ? (
                <SelectInput
                    label="Роль"
                    source="role"
                    choices={[
                        { id: 'admin', name: 'admin' },
                        { id: 'ge_employee', name: 'ge_employee' },
                        { id: 'employee', name: 'employee' },
                        { id: 'franchisee', name: 'franchisee' },
                        { id: 'tester', name: 'tester' },
                        { id: 'partner', name: 'partner' },
                        { id: 'partner_service', name: 'partner_service' },
                        { id: 'agent', name: 'agent' },
                    ]}
                    validate={required()}
                    className={classes.wide}
                />
            ) : (
                <SelectInput
                    label="Роль"
                    source="role"
                    choices={[
                        { id: 'employee', name: 'employee' },
                        { id: 'franchisee', name: 'franchisee' },
                        { id: 'tester', name: 'tester' },
                        { id: 'partner', name: 'partner' },
                        { id: 'partner_service', name: 'partner_service' },
                    ]}
                    validate={required()}
                    className={classes.wide}
                />
            )}
            <ReferenceInput
                perPage={10000}
                label="Франчайзи"
                source="franchisee_id"
                reference="franchisee"
                allowEmpty
                className={classes.wide}
            >
                <SelectInput optionText="name" className={classes.wide}/>
            </ReferenceInput>
            <ReferenceInput
                perPage={10000}
                label="Агент"
                source="agent_id"
                reference="agents"
                allowEmpty
                className={classes.wide}
            >
                <SelectInput optionText="name" className={classes.wide}/>
            </ReferenceInput>
            <ReferenceInput
                perPage={10000}
                label="Партнер"
                source="partner_id"
                reference="partners"
                allowEmpty
                className={classes.wide}
            >
                <SelectInput optionText="name" className={classes.wide}/>
            </ReferenceInput>
            <ReferenceInput
                perPage={10000}
                label="Сервисный партнер"
                source="partner_service_id"
                reference="partner_services"
                allowEmpty
                className={classes.wide}
            >
                <SelectInput optionText="name" className={classes.wide}/>
            </ReferenceInput>
        </SimpleForm>
    </Create>
));
