import React from 'react';
import {
    List,
    Datagrid,
    SelectInput,
    TextField,
    DateField,
    SimpleForm,
    Create,
    Edit,
    TextInput,
    Filter,
    email,
} from 'react-admin';
import { withStyles } from '@material-ui/core/styles';
import { CustomPagination, BulkDeleteButtonAdmin, ToolbarSaveAllDeleteAdmin, stylesWide } from './utils';

const AgentsFilter = props => (
    <Filter {...props}>
        <SelectInput
            label="Поле"
            source="field"
            choices={[
                { id: 'id', name: 'Id агента' },
                { id: 'name', name: 'Имя агента' },
                { id: 'details', name: 'Подробности' },
                { id: 'email', name: 'Электронная почта' },
                { id: 'status', name: 'Статус агента' },
                { id: 'contact', name: 'Контакты' },
                { id: 'succ_report_date', name: 'Последний отправленный отчет' },
                { id: 'created', name: 'Время создания' },
                { id: 'updated', name: 'Последнее обновление' },
            ]}
            alwaysOn
        />
        <TextInput
            label="Поиск"
            source="q"
            alwaysOn
        />
    </Filter>
);

export const AgentsList = ({ permissions, ...props }) => (
    <List
        {...props}
        filters={<AgentsFilter />}
        bulkActionButtons={<BulkDeleteButtonAdmin permissions={permissions} />}
        pagination={<CustomPagination />}
    >
        <Datagrid rowClick="edit">
            <TextField
                label="Id агента"
                source="id"
            />
            <TextField
                label="Имя агента"
                source="name"
            />
            <TextField
                label="Тип агента"
                source="type"
            />
            <TextField
                label="Подробности"
                source="details"
            />
            <TextField
                label="Электронная почта"
                source="email"
            />
            <TextField
                label="Статус агента"
                source="status"
            />
            <TextField
                label="Контакты"
                source="contact"
            />
            <DateField
                label="Последний отправленный отчет"
                source="succ_report_date"
                showTime
                locales="ru-RU"
            />
            <DateField
                label="Время создания"
                source="created"
                showTime
                locales="ru-RU"
            />
            <DateField
                label="Последнее обновление"
                source="updated"
                showTime
                locales="ru-RU"
            />
        </Datagrid>
    </List>
);

export const AddAgent = withStyles(stylesWide)(({classes, ...props}) => (
    <Create {...props} classes={classes}>
        <SimpleForm classes={classes}>
            <TextInput
                label="Имя агента"
                source="name"
                className={classes.wide}
            />
            <SelectInput
                label="Тип агента"
                source="type"
                choices={[
                    { id: 'internal', name: 'internal' },
                    { id: 'external', name: 'external' },
                ]}
                className={classes.wide}
            />
            <TextInput
                label="Подробности"
                source="details"
                className={classes.wide}
            />
            <TextInput
                label="Электронная почта"
                source="email"
                validate={email()}
                className={classes.wide}
            />
            <SelectInput
                label="Статус агента"
                source="status"
                choices={[
                    { id: 'active', name: 'active' },
                    { id: 'inactive', name: 'inactive' },
                ]}
                className={classes.wide}
            />
            <TextInput
                label="Контакты"
                source="contact"
                className={classes.wide}
            />
        </SimpleForm>
    </Create>
));

export const EditAgent = withStyles(stylesWide)(({ classes, permissions, ...props }) => (
    <Edit {...props} classes={classes}>
        <SimpleForm classes={classes} toolbar={<ToolbarSaveAllDeleteAdmin permissions={permissions}/>}>
            <TextInput
                disabled
                label="Id агента"
                source="id"
                className={classes.wide}
            />
            <TextInput
                label="Имя агента"
                source="name"
                className={classes.wide}
            />
            <SelectInput
                label="Тип агента"
                source="type"
                choices={[
                    { id: 'internal', name: 'internal' },
                    { id: 'external', name: 'external' },
                ]}
                className={classes.wide}
            />
            <TextInput
                label="Подробности"
                source="details"
                className={classes.wide}
            />
            <TextInput
                label="Электронная почта"
                source="email"
                validate={email()}
                className={classes.wide}
            />
            <SelectInput
                label="Статус агента"
                source="status"
                choices={[
                    { id: 'active', name: 'active' },
                    { id: 'inactive', name: 'inactive' },
                ]}
                className={classes.wide}
            />
            <TextInput
                label="Контакты"
                source="contact"
                className={classes.wide}
            />
            <TextInput
                disabled
                label="Время создания"
                source="created"
                className={classes.wide}
            />
            <TextInput
                disabled
                label="Последнее обновление"
                source="updated"
                className={classes.wide}
            />
        </SimpleForm>
    </Edit>
));
