import config from '../config';
import { showNotification } from 'react-admin';
import { io } from 'socket.io-client';
import { wait } from '../utils';

const env = process.env.REACT_APP_CUSTOM_NODE_ENV;
const URL = config[env].url;
const DELAY = 5000;
const permissions = localStorage.getItem('permissions');
const url =
    permissions === 'franchisee' || permissions === 'tester'
        ? `${URL}franchisee/`
        : permissions === 'partner_service'
        ? `${URL}partner_service/`
        : `${URL}admin/`;

export const loadSnoList = dispatch => {
    const token = localStorage.getItem('token');
    return fetch(`${url}test/stations`, {
        headers: { Authorization: `Bearer ${token}` },
    })
        .then(res => res.json())
        .then(snoList => {
            Array.isArray(snoList) &&
                dispatch({
                    type: 'SNO_LIST_LOAD',
                    payload: snoList,
                });
        });
};

export const loadSlotsList = (dispatch, selectedDevice) => {
    const token = localStorage.getItem('token');
    // if (selectedDevice !== '' && selectedDevice != null)
    return fetch(`${url}test/devices?sno=${selectedDevice}`, {
        headers: { Authorization: `Bearer ${token}` },
    })
        .then(res => {
            if (res.ok) {
                return res.json();
            } else {
                throw new Error();
            }
        })
        .then(slotsList => {
            dispatch({
                type: 'SLOTS_LIST_LOAD',
                payload: slotsList,
            });
        })
        .catch(() => {
            dispatch({
                type: 'SLOTS_LIST_ERROR',
            });
        });
    // else
    //     return dispatch({
    //         type: 'SLOTS_LIST_LOAD',
    //         payload: {},
    //     });
};

export const startLoading = dispatch => {
    return dispatch({
        type: 'LOADING_START',
    });
};

export const loadLogsList = (dispatch, active, datetime) => {
    const token = localStorage.getItem('token');
    if (active !== '')
        return fetch(`${url}test/logs?sno=${active}&datetime=${datetime}`, {
            headers: { Authorization: `Bearer ${token}` },
        })
            .then(res => res.json())
            .then(logsList => {
                dispatch({
                    type: 'LOGS_LIST_LOAD',
                    payload: logsList,
                });
            });
    else
        return dispatch({
            type: 'LOGS_LIST_LOAD',
            payload: [],
        });
};

export const extract = (dispatch, params, state) => {
    const token = localStorage.getItem('token');
    dispatch({ type: 'LOADING_START' });
    return wait(DELAY).then(() =>
        fetch(`${url}test/extract`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({
                sno: params.sno,
                position: params.position,
                dno: params.dno,
                dtype: params.dtype,
            }),
        })
            .then(
                response => {
                    if (response.status === 409) {
                        response.json().then((data) => {
                            dispatch(showNotification(data.message, 'warning'));
                        });
                    } else {
                        dispatch(showNotification('Ошибка: что-то пошло не так', 'warning'));
                    }
                    return dispatch({ type: 'EXTRACT', payload: response });
                },
                error => dispatch({ type: 'REQUEST_FAILED', error: error })
            )
            .then(() => loadSlotsList(dispatch, state.active))
            .finally(() => dispatch({ type: 'LOADING_END' }))
    );
};

export const open = (dispatch, params) => {
    const token = localStorage.getItem('token');
    dispatch({ type: 'LOADING_START' });
    return wait(DELAY).then(() =>
        fetch(`${url}test/open`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({
                sno: params.sno,
                position: params.position,
            }),
        })
            .then(response => {
                if (response.status === 200) {
                    dispatch(showNotification('Операция выполнена'));
                } else if (response.status === 409) {
                    response.json().then((data) => {
                        dispatch(showNotification(data.message, 'warning'));
                    });
                } else {
                    dispatch(showNotification('Ошибка: что-то пошло не так', 'warning'));
                }
            })
    );
};

export const choice = (dispatch, sno, socket) => {
    try {
        // socket.emit('subscribe-to-station-logs', sno);
        loadSlotsList(dispatch, sno);
        return dispatch({
            type: 'CHOICE',
            payload: { sno, datetime: Date.now() },
        });
    } catch (e) {
        console.error('Cannnot switch selected station logs', e);
    }
};

export const updateAdminStatus = (dispatch, params, state) => {
    const token = localStorage.getItem('token');
    dispatch({ type: 'LOADING_START' });
    return wait(DELAY)
        .then(() =>
            fetch(`${url}test/admin_status`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
                body: JSON.stringify({
                    dno: params.dno,
                    admin_status: params.admin_status,
                }),
            })
                .then(res => res.json())
                .then(device => {
                    dispatch({
                        type: 'ADMIN_STATUS_UPDATED',
                        payload: device,
                    });
                })
        )
        .then(() => loadSlotsList(dispatch, state.active));
};

export const updateConflictStatus = (dispatch, params, state) => {
    const token = localStorage.getItem('token');
    dispatch({ type: 'LOADING_START' });
    return wait(5000)
        .then(() =>
            fetch(`${url}test/conflict_status`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
                body: JSON.stringify({
                    dno: params.dno,
                    conflict_status: params.conflict_status,
                }),
            })
                .then(res => res.json())
                .then(device => {
                    dispatch({
                        type: 'CONFLICT_STATUS_UPDATED',
                        payload: device,
                    });
                })
        )
        .then(() => loadSlotsList(dispatch, state.active));
};

export const changeAllStatuses = (dispatch, params, state) => {
    const token = localStorage.getItem('token');
    dispatch({ type: 'LOADING_START' });
    return wait(DELAY).then(() =>
        fetch(`${url}test/change_all_statuses`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({
                sno: params.sno,
                conflict_status: params.conflict_status,
            }),
        }).then(response => {
            if (response.status === 200) {
                dispatch(showNotification('Операция выполнена'));
                loadSlotsList(dispatch, state.active);
            } else {
                dispatch(showNotification('Ошибка: что-то пошло не так', 'warning'));
            }
        })
    );
};

export const connect = (dispatch, active) => {
    const token = localStorage.getItem('token');
    const PATH = config[env].ioPrefix + '/test/logs';

    try {
        const socket = io(`${config[env].ws}`, {
            path: PATH,
            extraHeaders: {
                Authorization: `Bearer ${token}`,
            },
        });

        socket.on('connect', () => {
            if (active) {
                choice(dispatch, active, socket);
            }
            return dispatch({
                type: 'WS_CONNECTED',
                payload: socket,
            });
        });

        socket.on('station-log', data => {
            return dispatch({
                type: 'WS_MESSAGE',
                payload: data,
            });
        });

        socket.on('disconnect', () => {
            return dispatch({
                type: 'WS_CLOSED',
            });
        });
    } catch (e) {
        console.error(`Failed to connect ${PATH}`, e);
    }
};

export const restart = (dispatch, stationId) => {
    const token = localStorage.getItem('token');
    dispatch({ type: 'LOADING_START' });
    return wait(DELAY).then(() =>
        fetch(`${url}test/relink-stations/restart`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({
                stationId,
            }),
        }).then(response => {
            if (response.status === 200) dispatch(showNotification('Процесс перезагрузки запущен'));
            else if (response.status === 409) {
                response.json().then((data) => {
                    dispatch(showNotification(data.message, 'warning'));
                });
            } else dispatch(showNotification('Ошибка: не удалось перезагрузить станцию', 'warning'));
        }).finally(() => {
            dispatch({ type: 'LOADING_END' });
        }),
    );
};

export const disconnect = (dispatch, socket) => {
    try {
        socket.disconnect();
        return dispatch({
            type: 'WS_CLOSE',
        });
    } catch (e) {
        console.error(e);
    }
};
