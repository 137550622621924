import React, { useEffect } from 'react';
import { Layout } from 'react-admin';
import MyMenu from './MyMenu';
import MyAppBar from './MyAppBar';
import { connect } from 'react-redux';
import config from './config';
import { SET_GLOBAL_STATE_ACTION_KEY, STATE_REQUEST_HASH_KEY } from './GlobalState/constants';

const MyLayout = props => {
    const { dispatch, ...pr  } = props;

    useEffect(()=> {
        const env = process.env.REACT_APP_CUSTOM_NODE_ENV;
        const URL = config[env].url;
        const permissions = localStorage.getItem('permissions');
        const token = localStorage.getItem('token');

        let cachedState;
        try {
            cachedState = JSON.parse(localStorage.getItem(STATE_REQUEST_HASH_KEY));
        } catch(e) {
            cachedState = {};
        }

        const fetchData = async () => {
            let url;
            if (permissions === 'partner') {
                url = `${URL}partner/partners/state?rh=${cachedState?.requestHash}`;
            } else {
                return;
            }
            try {
              const headers = new Headers({ Accept: 'application/json' });
              headers.set('Authorization', `Bearer ${token}`);
              fetch(url, {
                  method: 'GET',
                  headers,
              })
              .then(response => {
                  if (response.status === 200) {
                    return response.json();
                  } else if (response.status === 304) {
                    return cachedState;
                  }
                  throw new Error('error fetch state');
              })
              .then((response) => {
                  dispatch({
                        type: SET_GLOBAL_STATE_ACTION_KEY,
                        payload: response.data,
                    });
                  localStorage.setItem(STATE_REQUEST_HASH_KEY, JSON.stringify(response));
                  return;
              });
            } catch (error) {
              return;
            }
          };

          fetchData();
    }, []);

    return (
        <Layout
        {...pr}
        menu={MyMenu}
        appBar={MyAppBar}
    />
    )
};

export default connect(undefined)(MyLayout);
