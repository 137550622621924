export const menuItems = [
    {
        icon: 'Dashboard',
        to: '/',
        primaryText: 'Главная',
        permissions: [
            'admin',
            'ge_employee',
            'employee',
            'franchisee',
            'tester',
            'partner',
            'agent',
            'partner_service',
        ],
    },
    {
        icon: 'InsertChart',
        to: '/franchisee_reports',
        primaryText: 'Отчеты франчайзи',
        permissions: ['admin', 'ge_employee', 'employee', 'franchisee'],
    },
    {
        icon: 'InsertChart',
        to: '/agent_reports',
        primaryText: 'Отчеты агентов',
        permissions: ['admin', 'ge_employee', 'employee', 'agent'],
    },
    {
        icon: 'InsertChart',
        to: '/partner_reports',
        primaryText: 'Отчеты партнеров',
        permissions: ['admin', 'ge_employee', 'employee', 'franchisee', 'partner', 'agent'],
    },
    {
        icon: 'InsertChart',
        to: '/admin_locations_reports',
        primaryText: 'Отчеты по локациям',
        permissions: ['admin', 'ge_employee', 'employee'],
    },
    {
        icon: 'InsertChart',
        to: '/franchisee_locations_reports',
        primaryText: 'Отчеты по локациям',
        permissions: ['franchisee'],
    },
    {
        icon: 'InsertChart',
        to: '/partner_locations_reports',
        primaryText: 'Отчеты по локациям',
        permissions: ['partner'],
    },
    {
        icon: 'InsertChart',
        to: '/agent_locations_reports',
        primaryText: 'Отчеты по локациям',
        permissions: ['agent'],
    },
    {
        icon: 'InsertChart',
        to: '/charts',
        primaryText: 'Диаграммы',
        permissions: ['admin', 'ge_employee', 'employee'],
    },
    {
        icon: 'Dvr',
        to: '/test',
        primaryText: 'Тест',
        permissions: [
            'admin',
            'ge_employee',
            'employee',
            'franchisee',
            'tester',
            'partner_service',
        ],
    },
    { type: 'divider' },
    {
        icon: 'People',
        to: '/admin_users',
        primaryText: 'Сотрудники',
        permissions: ['admin', 'ge_employee', 'employee'],
    },
    {
        icon: 'People',
        to: '/franchisee',
        primaryText: 'Франчайзи',
        permissions: ['admin', 'ge_employee', 'employee', 'franchisee'],
    },
    {
        icon: 'Assignment',
        to: '/ff_contracts',
        primaryText: 'Договоры франчайзи',
        permissions: ['admin', 'ge_employee', 'employee', 'franchisee'],
    },
    {
        icon: 'People',
        to: '/agents',
        primaryText: 'Агенты',
        permissions: ['admin', 'ge_employee', 'employee', 'agent'],
    },
    {
        icon: 'Assignment',
        to: '/agent_contracts',
        primaryText: 'Договоры агентов',
        permissions: ['admin', 'ge_employee', 'employee', 'agent'],
    },
    {
        icon: 'People',
        to: '/partners',
        primaryText: 'Партнеры',
        permissions: [
            'admin',
            'ge_employee',
            'employee',
            'franchisee',
            'partner',
            'agent',
            'partner_service',
        ],
    },
    {
        icon: 'Assignment',
        to: '/partner_contracts',
        primaryText: 'Договоры партнеров',
        permissions: ['admin', 'ge_employee', 'employee', 'franchisee', 'partner', 'agent'],
    },
    {
        icon: 'People',
        to: '/partner_services',
        primaryText: 'Сервисные партнеры',
        permissions: ['admin', 'ge_employee', 'employee'],
    },
    {
        icon: 'Assignment',
        to: '/partner_service_contracts',
        primaryText: 'Контракты СП',
        permissions: ['admin', 'ge_employee', 'employee'],
    },
    {
        icon: 'People',
        to: '/managers',
        primaryText: 'Менеджеры',
        permissions: ['admin', 'ge_employee', 'franchisee', 'partner_service'],
    },
    {
        icon: 'Assignment',
        to: '/manager_contracts',
        primaryText: 'Договоры менеджеров',
        permissions: ['admin', 'ge_employee', 'franchisee', 'partner_service'],
    },
    {
        icon: 'Place',
        to: '/locations',
        primaryText: 'Локации',
        permissions: [
            'admin',
            'ge_employee',
            'employee',
            'franchisee',
            'partner',
            'agent',
            'partner_service',
        ],
    },
    {
        icon: 'People',
        to: '/station_holders',
        primaryText: 'Владелец станций',
        permissions: ['admin', 'ge_employee', 'employee'],
    },
    { type: 'divider' },
    {
        icon: 'EvStation',
        to: '/stations',
        primaryText: 'Станции',
        permissions: [
            'admin',
            'ge_employee',
            'employee',
            'franchisee',
            'partner',
            'agent',
            'partner_service',
        ],
    },
    {
        icon: 'Crop75',
        to: '/slots',
        primaryText: 'Слоты',
        permissions: ['admin', 'ge_employee', 'employee'],
    },
    {
        icon: 'BatteryFull',
        to: '/devices',
        primaryText: 'Повербанки',
        permissions: ['admin', 'ge_employee', 'employee'],
    },
    { type: 'divider' },
    {
        icon: 'People',
        to: '/profiles',
        primaryText: 'Пользователи',
        permissions: ['admin', 'ge_employee', 'employee'],
    },
    {
        icon: 'People',
        to: '/external-profiles',
        primaryText: 'Внешние пользователи',
        permissions: ['admin', 'ge_employee', 'employee'],
    },
    {
        icon: 'Redeem',
        to: '/profile_bonus_history',
        primaryText: 'История бонусов',
        permissions: ['admin', 'ge_employee', 'employee'],
    },
    {
        icon: 'Notifications',
        to: '/players',
        primaryText: 'Push subscriptions',
        permissions: ['admin', 'ge_employee', 'employee'],
    },
    {
        icon: 'CreditCard',
        to: '/profile_payment_methods',
        primaryText: 'Методы оплаты',
        permissions: ['admin', 'ge_employee', 'employee'],
    },
    {
        icon: 'Timelapse',
        to: '/sessions',
        primaryText: 'Сессии',
        permissions: [
            'admin',
            'ge_employee',
            'employee',
            'franchisee',
            'agent',
            'partner_service',
        ],
    },
    {
        icon: 'Timelapse',
        to: '/partner_service_sessions',
        primaryText: 'Сервисные сессии',
        permissions: ['admin', 'ge_employee', 'employee','franchisee'],
    },
    {
        icon: 'AttachMoney',
        to: '/orders',
        primaryText: 'Заказы',
        permissions: ['admin', 'ge_employee', 'employee', 'franchisee'],
    },
    {
        icon: 'Payment',
        to: '/transactions_cp',
        primaryText: 'Транзакции CP',
        permissions: ['admin', 'ge_employee', 'employee'],
    },
    {
        icon: 'Payment',
        to: '/transactions_stripe',
        primaryText: 'Транзакции Stripe',
        permissions: ['admin', 'ge_employee', 'employee'],
    },
    {
        icon: 'AccountBox',
        to: '/stripe_customers',
        primaryText: 'Клиенты Stripe',
        permissions: ['admin', 'ge_employee', 'employee'],
    },
    {
        icon: 'Payment',
        to: '/transactions_payme',
        primaryText: 'Транзакции Payme',
        permissions: ['admin', 'ge_employee', 'employee'],
    },
    {
        icon: 'Payment',
        to: '/transactions_payplus',
        primaryText: 'Транзакции PayPlus',
        permissions: ['admin', 'ge_employee', 'employee'],
    },
    {
        icon: 'Phone',
        to: '/tele2_checks',
        primaryText: 'Tele2',
        permissions: ['admin', 'ge_employee', 'employee'],
    },
    {
        icon: 'Loyalty',
        to: '/external_promo',
        primaryText: 'Внешние промоакции',
        permissions: ['admin', 'ge_employee', 'employee'],
    },
    { type: 'divider' },
    {
        icon: 'Schedule',
        to: '/tariffs',
        primaryText: 'Тарифы',
        permissions: ['admin', 'ge_employee'],
    },
    {
        icon: 'List',
        to: '/cards_blacklist',
        primaryText: 'Блэклист',
        permissions: ['admin', 'ge_employee'],
    },
    {
        icon: 'ErrorOutline',
        to: '/stations_status',
        primaryText: 'Статусы станций',
        permissions: ['admin', 'ge_employee', 'employee', 'franchisee', 'partner_service'],
    },
    {
        icon: 'ErrorOutline',
        to: '/mismatch',
        primaryText: 'Mismatch',
        permissions: ['admin', 'ge_employee', 'partner_service'],
    },
    {
        icon: 'ErrorOutline',
        to: '/total_mismatch',
        primaryText: 'Total mismatch',
        permissions: ['admin', 'ge_employee', 'partner_service'],
    },
    {
        icon: 'Event',
        to: '/client_events',
        primaryText: 'События',
        permissions: ['admin', 'ge_employee'],
    },
    { type: 'divider' },
    {
        icon: 'TextFields',
        to: '/texts',
        primaryText: 'Тексты',
        permissions: ['admin', 'ge_employee'],
    },
];
