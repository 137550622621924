import React from 'react';
import {
    SelectInput,
    SimpleForm,
    Create,
    TextInput,
    required,
    ReferenceInput,
} from 'react-admin';
import { withStyles } from '@material-ui/core/styles';
import {
    stylesWide,
    isAdmin,
} from '../utils';

export const AddManager = withStyles(stylesWide)(({classes, permissions, ...props}) => (
  <Create {...props}>
      <SimpleForm>
          {isAdmin(permissions) && 
          <ReferenceInput
                perPage={10000}
                label="Принадлежит франчайзи"
                source="franchisee_id"
                reference="franchisee"
                className={classes.wide}
            >
            <SelectInput optionText="name" />
          </ReferenceInput>}
          {isAdmin(permissions) && 
          <ReferenceInput
                perPage={10000}
                label="Принадлежит сервисному партнеру"
                source="partner_service_id"
                reference="partner_services"
                className={classes.wide}
            >
            <SelectInput optionText="name" />
          </ReferenceInput>}
          <TextInput
              label="Имя менеджера"
              source="name"
              validate={[required()]}
              className={classes.wide}
          />
          <SelectInput
              label="Статус менеджера"
              source="status"
              choices={[
                  { id: 'active', name: 'active' },
                  { id: 'inactive', name: 'inactive' },
              ]}
              validate={[required()]}
              className={classes.wide}
          />
          <TextInput
              label="Контакты"
              source="contact"
              className={classes.wide}
          />
          <TextInput
              label="Подробности"
              source="details"
              className={classes.wide}
          />
      </SimpleForm>
  </Create>
));
