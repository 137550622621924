import React from 'react';

const mapPermsToName = (permissions) => {
  switch(permissions) {
    case 'admin':
      return 'администратора';
    case 'ge_employee':
      return 'сотрудника ГЭ';
    case 'employee':
      return 'сотрудника';
    case 'franchisee':
      return 'франчайзи';
    case 'partner':
      return 'партнера';
    case 'partner_service':
      return 'сервисного партнера';
    case 'agent':
      return 'агента';
    default:
      return '';
  }
}

const style = {
  padding: '10px',
  fontSize: '1.5rem',
  fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  marginBottom: '10px'
}

const nstyle = {
  fontWeight: 'bold',
}

export const GreetingBlock = ({permissions, name, referenceName}) => {
  return Boolean(name) && (
      <>
      <div style={style}>
        <span style={nstyle}>{`${name}`}</span>
        <span>{`, Добро пожаловать в кабинет ${mapPermsToName(permissions)} "${referenceName}"`}!</span>
      </div>
      </>
  );
}

export default GreetingBlock;
