import React, { Fragment }  from 'react';
import Card from '@material-ui/core/Card';

const style = {
    padding: '15px',
    fontSize: '1rem',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
}

const hstyle = {
    fontSize: '1.2rem',
    marginBottom: '4px',
    fontWeight: 'bold',
}

const dstype = {
    fontSize: '1rem',
    marginBottom: '10px',
    color: 'gray'
}

const SupportBlock = ({pages}) => {
    const support = pages?.dashboard?.blocks?.support;

    return (support?.enable && support?.contact) ? 
         (
                <Fragment>
                        <Card>
                            <div style={style}>
                                <div>
                                    <div style={hstyle}>Поддержка</div>
                                    <div style={dstype}>Вам доступна поддержка по ссылке</div>
                                </div>
                                <a href={support?.contact}>{support?.contact}</a>
                            </div>
                        </Card>
                </Fragment>
        ) : '';
}

export default SupportBlock;
