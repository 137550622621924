export const optionRenderer = choice =>
    choice.partner
        ? `${choice.id} (${choice.partner.name})`
        : choice.partner_service
        ? `${choice.id} (${choice.partner_service.name})`
        : `${choice.id}`;

export const managerOptionRenderer = choice =>
    choice.manager
        ? `[${choice.id}] ${choice.manager.name}` + (choice.manager.contact ? `, ${choice.manager.contact}` : '')
        : `${choice.id}`;
