import { SET_GLOBAL_STATE_ACTION_KEY } from "./constants";

/**
 * Глобальное состояние пользователя во всем приложении, которое формируется на бэкенде
 * и диктует правила доступов и элементов интерфейса
 */
const initialState = {
    user: {
      name: '',
      permissions: '',
      referenceName: '',
    },
    pages: {},
};

const globalState = (state = initialState, action) => {
  switch (action.type) {
    case SET_GLOBAL_STATE_ACTION_KEY:
      return {
        ...state,
        ...action.payload
      };
    default:
      return state;
  }
};

export default globalState;
