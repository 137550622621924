import React from 'react';
import Card from '@material-ui/core/Card';

const style = {
    padding: '15px',
    fontSize: '1rem',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
}

const hstyle = {
    fontSize: '1.2rem',
    marginBottom: '4px',
    fontWeight: 'bold',
}

const dstype = {
    fontSize: '1rem',
    color: 'gray'
}

const ManagerBlock = ({pages}) => {
    const manager = pages.dashboard?.blocks?.manager;

    return (manager?.enable) ? 
         (
            <Card>
                <div style={style}>
                    <div style={hstyle}>Менеджер</div>
                    <div style={dstype}>Контакт менеджера по каждой точке вы можете найти в разделе "Локации"</div>
                </div>
            </Card>
        ) : '';
}

export default ManagerBlock;
