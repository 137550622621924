
import React from 'react';
import {
    SelectInput,
    SimpleForm,
    Edit,
    TextInput,
    required,
    ReferenceInput,
} from 'react-admin';
import { withStyles } from '@material-ui/core/styles';
import { ToolbarSaveAllDeleteAdmin, stylesWide, isAdmin } from '../utils';


export const EditManager = withStyles(stylesWide)(({ classes, permissions, ...props }) => (
  <Edit {...props} classes={classes}>
      <SimpleForm classes={classes} toolbar={<ToolbarSaveAllDeleteAdmin permissions={permissions} />}>
          <TextInput
              disabled
              label="Id менеджера"
              source="id"
              className={classes.wide}
          />
          {isAdmin(permissions) && 
          <ReferenceInput
                perPage={10000}
                label="Принадлежит франчайзи"
                source="franchisee_id"
                reference="franchisee"
                className={classes.wide}
            >
            <SelectInput optionText="name" />
          </ReferenceInput>}
          {isAdmin(permissions) && 
          <ReferenceInput
                perPage={10000}
                label="Принадлежит сервисному партнеру"
                source="partner_service_id"
                reference="partner_services"
                className={classes.wide}
            >
            <SelectInput optionText="name" />
          </ReferenceInput>}
          <TextInput
              label="Имя менеджера"
              source="name"
              validate={[required()]}
              className={classes.wide}
          />
          <SelectInput
              label="Статус менеджера"
              source="status"
              choices={[
                  { id: 'active', name: 'active' },
                  { id: 'inactive', name: 'inactive' },
              ]}
              validate={[required()]}
              className={classes.wide}
          />
          <TextInput
              label="Подробности"
              source="details"
              className={classes.wide}
          />
          <TextInput
              label="Контакты"
              source="contact"
              className={classes.wide}
          />
          <TextInput
              disabled
              label="Время создания"
              source="created"
              className={classes.wide}
          />
          <TextInput
              disabled
              label="Последнее обновление"
              source="updated"
              className={classes.wide}
          />
      </SimpleForm>
  </Edit>
));
