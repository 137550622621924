import {
    ArrayField,
    ArrayInput,
    BooleanInput,
    Datagrid,
    DateField,
    Edit,
    ImageField,
    ImageInput,
    number,
    ReferenceInput,
    required,
    SelectInput,
    SimpleForm,
    SimpleFormIterator,
    TextField,
    TextInput,
} from 'react-admin';
import { Field } from 'redux-form';
import {
    isAdmin,
    isFranchisee,
    isEmployee,
    isGeEmployee,
    isPartnerService,
    ToolbarSaveAllDeleteAdmin,
} from '../utils';
import { LocalizedDataInput } from '../localizedDataInput/LocalizedDataInput';
import { countryOptions } from '../constants/countries';
import { tariffOptionsLocations } from '../constants/tariffs';
import { DateInput, TimeInput } from 'react-admin-date-inputs';
import React from 'react';
import { optionRenderer, managerOptionRenderer } from './utils';
import { EditableScheduleField, Timezone, TypeField } from './components';
import { withStyles } from '@material-ui/core/styles';
import { stylesWide } from '../utils';


const EditLocation = withStyles(stylesWide)(({ classes, permissions, ...props }) => {
    return (
        <Edit {...props} classes={classes}>
            <SimpleForm classes={classes} toolbar={<ToolbarSaveAllDeleteAdmin permissions={permissions} />}>
                <TextInput
                    disabled
                    label="Id локации"
                    source="id"
                    className={classes.wide}
                />
                {!isPartnerService(permissions) && (
                    <ReferenceInput
                        perPage={10000}
                        label="Id договора с партнером"
                        source="contract_id"
                        reference="partner_contracts"
                        filter={{ partner: true }}
                        allowEmpty
                        parse={value => value || null}
                        className={classes.wide}
                    >
                        <SelectInput optionText={optionRenderer}className={classes.wide}/>
                    </ReferenceInput>
                )}
                {(isAdmin(permissions) || isPartnerService(permissions)) && (
                    <ReferenceInput
                        perPage={10000}
                        label="Id договора c сервисным партнером"
                        source="partner_service_contract_id"
                        reference={
                            isPartnerService(permissions)
                                ? 'partner_contracts'
                                : 'partner_service_contracts'
                        }
                        filter={{ partner_service: true }}
                        allowEmpty
                        parse={value => value || null}
                        className={classes.wide}
                    >
                        <SelectInput optionText={optionRenderer} className={classes.wide}/>
                    </ReferenceInput>
                )}
                {(isFranchisee(permissions) || isPartnerService(permissions)) && (
                    <ReferenceInput
                        perPage={10000}
                        label="Id договора c менеджером"
                        source="manager_contract_id"
                        reference='manager_contracts'
                        filter={{ manager: true }}
                        allowEmpty
                        parse={value => value || null}
                        className={classes.wide}
                    >
                        <SelectInput optionText={managerOptionRenderer} className={classes.wide}/>
                    </ReferenceInput>
                )}
                <BooleanInput
                    label="Депозит"
                    source="deposit_feature"
                    className={classes.wide}
                />
                <LocalizedDataInput
                    label="Название"
                    source="name_localized"
                    type="edit"
                    className={classes.wide}
                />
                <LocalizedDataInput
                    label="Адрес"
                    source="address_localized"
                    type="edit"
                    className={classes.wide}
                />
                <SelectInput
                    label="Страна"
                    source="country"
                    choices={countryOptions}
                    className={classes.wide}
                />
                <LocalizedDataInput
                    label="Город"
                    source="city_localized"
                    type="edit"
                    className={classes.wide}
                />
                <SelectInput
                    label="Категория"
                    source="category"
                    choices={[
                        { id: 'Общепит', name: 'Общепит' },
                        { id: 'Путешествия', name: 'Путешествия' },
                        { id: 'Спорт', name: 'Спорт' },
                        { id: 'Красота и здоровье', name: 'Красота и здоровье' },
                        { id: 'Развлечения и досуг', name: 'Развлечения и досуг' },
                        { id: 'Транспорт', name: 'Транспорт' },
                        { id: 'Разное', name: 'Разное' },
                        { id: 'Другое', name: 'Другое' },
                    ]}
                    allowEmpty
                    className={classes.wide}
                />
                <TypeField
                    label="Тип"
                    source="type"
                    className={classes.wide}
                />
                <TextInput
                    label="Широта"
                    source="latitude"
                    validate={required()}
                    className={classes.wide}
                />
                <TextInput
                    label="Долгота"
                    source="longitude"
                    validate={required()}
                    className={classes.wide}
                />
                <TimeInput
                    label="Начало работы"
                    source="start_daytime"
                    options={{ ampm: false }}
                    className={classes.wide}
                    disabled
                />
                <TimeInput
                    label="Конец работы"
                    source="end_daytime"
                    options={{ ampm: false }}
                    className={classes.wide}
                    disabled
                />
                <TextInput
                    label="Время работы"
                    source="work_duration"
                    validate={number()}
                    className={classes.wide}
                    disabled
                />

                <Field
                    name={'timezone'}
                    label={'Временная зона'}
                    component={Timezone}
                    className={classes.wide}
                />
                <EditableScheduleField source="schedule" />
                <TextInput
                    label="Гайд"
                    disabled={!isAdmin(permissions)}
                    source="guide"
                    className={classes.wide}
                />
                <LocalizedDataInput
                    label="Гайд"
                    source="guide_localized"
                    type="edit"
                    className={classes.wide}
                />
                <TextInput
                    label="Комментарии"
                    source="comment"
                    className={classes.wide}
                />
                <SelectInput
                    label="Статус"
                    source="status"
                    choices={[
                        { id: 'active', name: 'active' },
                        { id: 'inactive', name: 'inactive' },
                    ]}
                    className={classes.wide}
                />
                <SelectInput
                    label="Видимость на карте"
                    source="visibility"
                    choices={[
                        { id: 'visible', name: 'visible' },
                        { id: 'hidden', name: 'hidden' },
                    ]}
                    className={classes.wide}
                />
                <SelectInput
                    label="Опция тарифа"
                    source="tariff_option"
                    choices={tariffOptionsLocations}
                    allowEmpty
                    className={classes.wide}
                />
                <SelectInput
                    label="Опция тарифа первой сессии"
                    source="tariff_option_first_session"
                    choices={tariffOptionsLocations}
                    allowEmpty
                    className={classes.wide}
                />
                {isFranchisee(permissions) && (
                    <DateInput
                        label="Бонус с"
                        source="bonus_start_date"
                        options={{ format: 'dd.MM.YYYY' }}
                    />
                )}
                {isAdmin(permissions) && (
                    <ArrayInput
                        label="Внешние промоакции"
                        source="promos"
                        className={classes.wide}
                    >
                        <SimpleFormIterator>
                            <ReferenceInput
                                label="Промо"
                                source="promo_id"
                                reference="external_promo"
                                allowEmpty
                                className={classes.wide}
                            >
                                <SelectInput
                                    className={classes.wide}
                                    optionText={choice => `${choice.id} (${choice.name})`}
                                />
                            </ReferenceInput>
                        </SimpleFormIterator>
                    </ArrayInput>
                )}
                {!isAdmin(permissions) && isGeEmployee(permissions) && (
                    <ArrayField
                        label="Внешние промоакции"
                        source="promos"
                    >
                        <Datagrid
                            rowClick={recStr => {
                                const rec = JSON.parse(recStr);
                                return `/external_promo/${rec.promo_id}/show`;
                            }}
                        >
                            <TextField
                                label="Id"
                                source="promo_id"
                                className={classes.wide}
                            />
                            <DateField
                                label="Время добавления"
                                source="created"
                                showTime
                                locales="ru-RU"
                                className={classes.wide}
                            />
                        </Datagrid>
                    </ArrayField>
                )}
                <ImageInput
                    source="photo"
                    label="Фото"
                    accept="image/*"
                    multiple={true}
                    className={classes.wide}
                >
                    <ImageField
                        source="src"
                        title="name"
                    />
                </ImageInput>
            </SimpleForm>
        </Edit>
    );
});

export default EditLocation;
