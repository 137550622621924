import React from 'react';
import {
    ReferenceInput,
    SelectInput,
    SimpleForm,
    Create,
    TextInput,
    required,
    number,
    minValue,
    maxValue,
} from 'react-admin';
import { DateInput } from 'react-admin-date-inputs';
import { withStyles } from '@material-ui/core/styles';
import {
  stylesWide,
} from '../utils';


export const AddManagerContract = withStyles(stylesWide)(({classes, ...props}) => (
  <Create {...props} classes={classes}>
      <SimpleForm classes={classes}>
          <ReferenceInput
            perPage={10000}
            label="Имя менеджера"
            source="manager_id"
            reference="managers"
            validate={[required()]}
            className={classes.wide}
          >
            <SelectInput optionText="name" className={classes.wide}/>
          </ReferenceInput>
          <TextInput
            label="Комиссия"
            source="com_cond"
            validate={[required(), number(), minValue(0), maxValue(1)]}
            className={classes.wide}
          />
          <DateInput
              label="Дата заключения договора"
              source="start_date"
              options={{ format: 'dd.MM.YYYY' }}
              validate={[required()]}
              className={classes.wide}
          />
          <DateInput
              label="Дата расторжения договора"
              source="end_date"
              options={{ format: 'dd.MM.YYYY' }}
              className={classes.wide}
          />
          <TextInput
              label="Подробности"
              source="details"
              className={classes.wide}
          />
      </SimpleForm>
  </Create>
));
